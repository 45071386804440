// Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&family=Prata&display=swap");

// Variables
$gray: #4b4f53;
$orange: #d4795e;
$red: #ae5755;
$gold: #d08337;
$blue: #9fc6dd;
$cream: #fffbf1;

$main-font: "Montserrat";

$tablet-width: 768px;

body {
  margin: 0;
  background-color: $cream !important;
}

.page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}

.navbar-collapse {
  display: flex;
  justify-content: center;

  .navbar-nav {
    .nav-link {
      font-family: $main-font;
      color: $blue !important;
      font-size: 1.5rem;
      font-weight: 400;
      margin: 0 1rem;

      &.active {
        color: $gray !important;
        border-bottom: 0.2rem solid $gray;
      }
    }
  }
}

.navbar-toggler {
  border: 0.2rem solid $blue !important;
  border-radius: 0 !important;

  &:focus,
  &:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
}

.navbar.navbar-expand-sm {
  .navbar-nav {
    text-align: center;
  }
}


.info-container {
  margin: 5rem;
  text-align: center;
  font-family: $main-font;
  color: $gray;

  .wedding-date {
    font-size: 3rem;
  }

  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: $red;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 3rem;
    white-space: pre-wrap !important;

    &.bold {
      font-weight: bold;
    }
  }

  a {
    color: $blue;

    &:hover {
      color: $red;
    }
  }
}

.party-group {
  margin: 3rem 0;
}

.party-member {
  display: flex;
  flex-direction: column;


  &.bm {
    align-items: flex-end;
    padding: 0 3rem;
  }

  &.gm {
    align-items: flex-start;
    padding: 0 3rem;
  }
}

.party-member-title {
  color: $gray;
  font-size: 1.5rem;
  border-bottom: 0.1rem solid $blue;
}

.party-member-subtitle {
  color: $gray;
}

@media only screen and (max-width: $tablet-width) {
  .info-container {
    margin: 5rem 1rem;
  }
  

  .party-member {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
  
    &.bm {
      align-items: center;
      padding: 0 3rem;
    }
  
    &.gm {
      align-items: center;
      padding: 0 3rem;
    }
  }

  .party-member-title {
    font-size: 1rem;
  }

}
