@import "../App.scss";

.logo-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
}

.logo-text {
    display: flex;
    flex-direction: row;
    font-size: 3rem;
    font-family: "Lora";
    font-weight: bold;

    .logo-name {
        margin: 0 1rem;
        color: $red;
    }

    .logo-divider {
        color: $orange;
        font-weight: normal;
    }
}

.logo-bars {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;

    .logo-bar {
        width: 100%;
        height: 0.3rem;

        &.gray {
            background-color: $gray;
        }

        &.blue {
            background-color: $blue;
        }

        &.red {
            background-color: $red;
        }

        &.orange {
            background-color: $orange;
        }
    }
}
